<template>
  <v-dialog :title="title" :visible.sync="localVisible" :size="dialogSize" class="notifications-info-dialog">
    <time class="notifications-info-dialog__date">{{ dateString }}</time>

    <p class="notifications-info-dialog__description">
      {{ actionText }}

      <router-link :to="targetLink" target="_blank" class="notifications-info-dialog__description-target name-and-number-link">
        {{ safeLastName ? safeLastName : 'имя не указано' }} {{ target }}
      </router-link>
    </p>

    <div v-if="isContentVisible" body-scroll-lock-ignore class="notifications-info-dialog__changes">
      <notifications-changed-field-list
        v-if="hasChangedFields && !isNotificationsHistoryEntry"
        class="notifications-info-dialog__changed-fields"
        :changes="changedFields"
        :object-type="localModel.objectType"
      />

      <div v-if="isRequestShow">
        <p class="notifications-info-dialog__date show-request-added">ДОБАВЛЕН ЗАПРОС НА ПРОСМОТР</p>
      </div>

      <div v-if="isRequestShow" class="notifications-info-dialog__request-show">
        <p>
          <span class="notifications-deleted-show__field-title">КЛИЕНТ</span> <br />
          {{ phoneTitle(localModel.content.object.account.phone) }} -
          {{ localModel.content.object.account.name ? localModel.content.object.account.name : 'имя не указано' }}  {{ localModel.content.objectDraft.account.lastName }}
        </p>
        <span class="notifications-deleted-show__line" />
        <p>
          <span class="notifications-deleted-show__field-title">АГЕНТ</span> <br />
          {{ localModel.content.object.agent.name }}
        </p>
        <span class="notifications-deleted-show__line" />
        <p>
          <span class="notifications-deleted-show__field-title">ОБЪЕКТ НЕДВИЖИМОСТИ</span> <br />
          {{ localModel.content.object.advert.id }}
          {{ localModel.content.object.advert.name }}
          {{ localModel.content.object.advert.rounded_price }}
        </p>
        <span class="notifications-deleted-show__line" />
        <p>
          <span class="notifications-deleted-show__field-title">ДАТА ПОКАЗА</span> <br />
          {{ localModel.content.object.showDate }}
        </p>
        <span class="notifications-deleted-show__line" />
        <p>
          <span class="notifications-deleted-show__field-title">ВРЕМЯ ПОКАЗА</span> <br />
          {{ localModel.content.object.showTime }}
        </p>
      </div>
      <template v-for="(images, index) in filesMap.IMAGES">
        <notifications-changed-images
          v-if="images.list.length"
          :key="index"
          class="notifications-info-dialog__changed-fields"
          :list="images.list"
          :action="images.action"
        />
      </template>
      <template v-for="(documents, index) in filesMap.DOCUMENTS">
        <notifications-changed-documents
          v-if="documents.list.length"
          :key="index"
          class="notifications-info-dialog__changed-fields"
          :list="documents.list"
          :action="documents.action"
        />
      </template>
      <template v-for="(showAct, index) in filesMap.SHOW_ACTS">
        <notifications-changed-files
          v-if="showAct.list.length"
          :key="index"
          class="notifications-info-dialog__changed-fields"
          :list="showAct.list"
          :action="showAct.action"
        ></notifications-changed-files>
      </template>
      <notifications-deleted-show v-if="deletedShow" :show-data="deletedShow" />
      <notifications-history-entry
        v-if="isNotificationsHistoryEntry"
        class="notifications-info-dialog__changed-fields"
        v-bind="localModel.content && localModel.content.object"
        :action="localModel.action"
        :object-type="localModel.objectType"
        :changes="changedFields"
        :added-files="addedHistoryFiles"
        :deleted-files="deletedHistoryFiles"
      >
      </notifications-history-entry>
    </div>

    <div class="notifications-info-dialog__buttons">
      <v-button
        v-for="({ text, handler = null, attrs = null }, index) in dialogActions"
        :key="index"
        v-bind="attrs"
        class="notifications-info-dialog__button"
        @click="handler"
      >
        {{ text }}
      </v-button>
    </div>
  </v-dialog>
</template>

<script>
import NotificationsChangedFieldList from '@/components/Notifications/ChangedFieldList.vue'
import NotificationsChangedImages from '@/components/Notifications/ChangedImages.vue'
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/common/VButton.vue'
import { formatDateTime, formatMoney } from '@/utils/formatters'
import {
  APPLY_EDIT_OBJECT,
  APPLY_NEW_OBJECT,
  DELETE_OBJECT,
  EDIT_OBJECT,
  NEW_OBJECT,
  REJECT_EDIT_OBJECT,
  REJECT_NEW_OBJECT,
  APPLY_DELETE_OBJECT,
  REJECT_DELETE_OBJECT,
  SHOW_REQUEST,
  SHOW_EDIT_REQUEST
} from '@/constants/notificationActions'
import {
  ADVERT,
  ADVERT_CALL,
  ADVERT_SHOW,
  CUSTOMER_PROFILE,
  HISTORY_ENTRY_CUSTOMER,
  HISTORY_ENTRY_OWNER,
  OWNER_PROFILE
} from '@/constants/notificationObjectTypes'
import NotificationsChangedFiles from '@/components/Notifications/ChangedFiles.vue'
import NotificationsChangedDocuments from '@/components/Notifications/ChangedDocuments.vue'
import NotificationsHistoryEntry from '@/components/Notifications/HistoryEntry.vue'
import { DRAFT_STATUS_DRAFT } from '@/constants/draftStatuses'
import { CURRENCY_SYMBOLS_MAP, RUB } from '@/constants/currencys'
import { DESCRIPTIONS_MAP } from '@/constants/notificationDescriptions'
import NotificationsDeletedShow from '@/components/Notifications/DeletedShow.vue'
import { formatPhone } from '@/utils/formatters'


export default {
  name: 'NotificationsInfoDialog',
  components: {
    NotificationsDeletedShow,
    NotificationsChangedFiles,
    NotificationsHistoryEntry,
    NotificationsChangedDocuments,
    VDialog,
    VButton,
    NotificationsChangedFieldList,
    NotificationsChangedImages
  },
  props: {
    visible: { type: Boolean, required: true },
    model: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    safeLastName() {
    return this.localModel?.content?.objectDraft?.account?.lastName || '';
    },
    isRequestShow() {
      return (
        this.localModel &&
        this.localModel.action === SHOW_REQUEST &&
        this.localModel.objectType === ADVERT_SHOW
      )
    },
    filesMap() {
      return {
        DOCUMENTS: {
          added: { list: this.addedDocuments, action: 'added' },
          deleted: { list: this.deletedDocuments, action: 'deleted' }
        },
        SHOW_ACTS: {
          added: { list: this.addedShowAct, action: 'added' },
          deleted: { list: this.deletedShowAct, action: 'deleted' }
        },
        IMAGES: {
          added: { list: this.addedMedia, action: 'added' },
          deleted: { list: this.deletedMedia, action: 'deleted' }
        }
      }
    },
    isNotificationsHistoryEntry() {
      return (
        this.localModel?.objectType === HISTORY_ENTRY_OWNER || this.localModel?.objectType === HISTORY_ENTRY_CUSTOMER
      )
    },
    dateString() {
      return formatDateTime(this.localModel.createdAt, ' ', true, true)
    },
    localModel() {
      return this.model || {}
    },
    title() {
      return `Уведомление ${this.localModel.id}`
    },
    notificationType() {
      return this.localModel?.action
    },
    actionText() {
      const {
        action,
        objectType,
        content: {
          object: { id }
        }
      } = this.localModel
      return `${DESCRIPTIONS_MAP[action]?.[objectType]}${id && objectType === ADVERT ? ` №${id}` : ''}: `
    },
    isTargetWithPhone() {
      return [
        OWNER_PROFILE,
        CUSTOMER_PROFILE,
        HISTORY_ENTRY_CUSTOMER,
        HISTORY_ENTRY_OWNER,
        ADVERT_CALL,
        ADVERT_SHOW
      ].includes(this.localModel.objectType)
    },
    target() {
      let targetObject = {}
      if ([ADVERT_CALL, ADVERT_SHOW].includes(this.localModel.objectType)) {
        targetObject = this.localModel?.content?.object?.account
      } else {
        targetObject = this.isDraftNotification
          ? this.localModel?.content?.objectDraft
          : this.localModel?.content?.object
      }
      if (this.isTargetWithPhone) {
        return `${targetObject?.client?.name || targetObject?.name} / ${targetObject?.client?.phone ||
          targetObject?.phone}`
      }
      return targetObject?.name
    },
    targetLink() {
      const callTargetRole =
        this.localModel.objectType === ADVERT_CALL ? this.localModel?.content?.object?.account?.role : null
      const callTargetRoleIsOwner = callTargetRole && callTargetRole === 'owner'

      const LINKS_MAP = {
        [OWNER_PROFILE]: { name: 'owner-edit', params: { ownerId: this.localModel?.content?.object?.id } },
        [HISTORY_ENTRY_OWNER]: {
          name: 'owner-edit',
          params: { ownerId: this.localModel?.content?.object?.client?.id }
        },
        [CUSTOMER_PROFILE]: {
          name: 'customer-edit',
          params: { customerId: this.localModel?.content?.object?.id }
        },
        [HISTORY_ENTRY_CUSTOMER]: {
          name: 'customer-edit',
          params: { customerId: this.localModel?.content?.object?.client?.id }
        },
        [ADVERT]: {
          name: 'objects-realty-edit',
          params: {
            status: this.isDraftNotification ? DRAFT_STATUS_DRAFT : undefined,
            objectId: this.localModel?.content[this.isDraftNotification ? 'objectDraft' : 'object'].id
          }
        },
        [ADVERT_CALL]: {
          name: callTargetRoleIsOwner ? 'owner-edit' : 'customer-edit',
          params: {
            [callTargetRoleIsOwner ? 'ownerId' : 'customerId']: this.localModel?.content?.object?.account?.id
          }
        },
        [ADVERT_SHOW]: {
          name: 'customer-edit',
          params: { customerId: this.localModel?.content?.object?.account?.id }
        }
      }
      return LINKS_MAP[this.localModel.objectType]
    },
    isDraftNotification() {
      return [NEW_OBJECT, REJECT_NEW_OBJECT].includes(this.notificationType)
    },
    isAdmin() {
      return [NEW_OBJECT, EDIT_OBJECT, DELETE_OBJECT].includes(this.notificationType)
    },
    changedFields() {
      const changes = this.localModel?.content?.changes || null
      const { auxiliaryCurrency, auxiliaryPrice, price, ...otherChanges } = changes
      if (auxiliaryCurrency || auxiliaryPrice || price) {
        return { ...otherChanges, price: this.getFormattedPrice() }
      }
      return changes
    },
    deletedMedia() {
      return this.localModel?.content?.objectDraft?.mediaDelete || []
    },
    addedMedia() {
      return this.localModel?.content?.objectDraft?.mediaAdd || []
    },
    isDeletionNotification() {
      return [DELETE_OBJECT, APPLY_DELETE_OBJECT, REJECT_DELETE_OBJECT].includes(this.notificationType)
    },
    deletedShow() {
      return (
        (this.isDeletionNotification &&
          this.localModel.objectType === ADVERT_SHOW &&
          this.localModel?.content?.object) ||
        null
      )
    },
    deletedShowAct() {
      return (!this.isDeletionNotification && this.localModel?.content?.objectDraft?.photosDelete) || []
    },
    addedShowAct() {
      return (!this.isDeletionNotification && this.localModel?.content?.objectDraft?.photosAdd) || []
    },
    addedDocuments() {
      return this.localModel?.content?.objectDraft?.filesAdd || []
    },
    deletedDocuments() {
      return this.localModel?.content?.objectDraft?.filesDelete || []
    },
    addedHistoryFiles() {
      return this.localModel?.content?.objectDraft?.filesAdd || []
    },
    deletedHistoryFiles() {
      return this.localModel?.content?.objectDraft?.filesDelete || []
    },
    dialogSize() {
      return this.deletedMedia.length + this.addedMedia.length > 0 ? 'large' : 'normal'
    },
    hasChangedFields() {
      return this.changedFields && Object.entries(this.changedFields).length > 0
    },
    isContentVisible() {
      return !(
        (this.localModel.action === NEW_OBJECT && this.localModel.objectType === ADVERT) ||
        ([REJECT_NEW_OBJECT, APPLY_NEW_OBJECT].includes(this.localModel.action) &&
          this.localModel.objectType !== ADVERT_CALL)
      )
    },
    isActionsDisabledForAdmin() {
      return !this.localModel?.canBeApproved
    },
    isActionsDisabledForAgent() {
      return !this.localModel?.canBeFixed
    },

    dialogActions() {
      const { action } = this.localModel

      const getNewObjectActionParams = () => {
        if ([HISTORY_ENTRY_OWNER, HISTORY_ENTRY_CUSTOMER, ADVERT_CALL].includes(this.localModel?.objectType)) {
          return [{ text: 'Хорошо', handler: this.close, attrs: { primary: true } }]
        }
        return [
          {
            text: 'Проверить',
            handler: this.check,
            attrs: { primary: true, disabled: this.isActionsDisabledForAdmin }
          }
        ]
      }
      const getEditObjectActionParams = () => {
        if ([ADVERT_SHOW].includes(this.localModel?.objectType)) {
          return [{ text: 'Хорошо', handler: this.close, attrs: { primary: true } }]
        }
        return [
          {
            text: 'Отказать в изменениях',
            handler: this.reject,
            attrs: { disabled: this.isActionsDisabledForAdmin }
          },
          {
            text: 'Подтвердить изменения',
            handler: this.accept,
            attrs: { primary: true, disabled: this.isActionsDisabledForAdmin }
          }
        ]
      }
      const buttonsMap = new Map([
        [NEW_OBJECT, getNewObjectActionParams()],
        [EDIT_OBJECT, getEditObjectActionParams()],
        [
          DELETE_OBJECT,
          [
            {
              text: 'Отказать в изменениях',
              handler: this.reject,
              attrs: { disabled: this.isActionsDisabledForAdmin }
            },
            {
              text: 'Подтвердить изменения',
              handler: this.accept,
              attrs: { primary: true, disabled: this.isActionsDisabledForAdmin }
            }
          ]
        ],
        [APPLY_EDIT_OBJECT, [{ text: 'Хорошо', handler: this.close, attrs: { primary: true } }]],
        [APPLY_NEW_OBJECT, [{ text: 'Хорошо', handler: this.close, attrs: { primary: true } }]],
        [APPLY_DELETE_OBJECT, [{ text: 'Хорошо', handler: this.close, attrs: { primary: true } }]],
        [
          REJECT_EDIT_OBJECT,
          [{ text: 'Исправить', handler: this.fix, attrs: { primary: true, disabled: this.isActionsDisabledForAgent } }]
        ],
        [
          REJECT_NEW_OBJECT,
          [{ text: 'Исправить', handler: this.fix, attrs: { primary: true, disabled: this.isActionsDisabledForAgent } }]
        ],
        [
          REJECT_DELETE_OBJECT,
          [{ text: 'Исправить', handler: this.fix, attrs: { primary: true, disabled: this.isActionsDisabledForAgent } }]
        ],
        [SHOW_REQUEST, [
          {
            text: 'Отменить',
            handler: this.deny,
            attrs: { disabled: this.isActionsDisabledForAgent } // Неактивна, если can_be_fixed === false
          },
          {
            text: 'Перенести',
            handler: this.rescheduleShow,
            attrs: { disabled: this.isActionsDisabledForAgent } // Неактивна, если can_be_fixed === false
          },
          {
            text: 'Подтвердить',
            handler: this.confirm,
            attrs: { primary: true, disabled: this.isActionsDisabledForAgent } // Неактивна, если can_be_approved === false
          }
        ]],
        [SHOW_EDIT_REQUEST, [
        {
            text: 'Отменить',
            handler: this.denyReschedule,
            attrs: { disabled: this.isActionsDisabledForAgent } // Неактивна, если can_be_fixed === false
          },
          {
            text: 'Подтвердить',
            handler: this.confirmReschedule,
            attrs: { primary: true, disabled: this.isActionsDisabledForAgent } // Неактивна, если can_be_approved === false
          }
        ]]
      ])

      const currentButtons = buttonsMap.get(action)
      return Array.isArray(currentButtons) ? currentButtons : []
    },

    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    checkShowsList() {
      this.$router.push('/shows');
    },
    getFormattedPrice() {
      const {
        auxiliaryCurrency: newAuxiliaryCurrency,
        auxiliaryPrice: newAuxiliaryPrice,
        price: newPrice
      } = this.localModel?.content?.objectDraft
      const {
        auxiliaryCurrency: oldAuxiliaryCurrency,
        auxiliaryPrice: oldAuxiliaryPrice,
        price: oldPrice
      } = this.localModel?.content?.object
      return {
        newValue: `${formatMoney(newAuxiliaryPrice || newPrice)} ${
          CURRENCY_SYMBOLS_MAP[newAuxiliaryCurrency || RUB.value]
        }`,
        oldValue: `${formatMoney(oldAuxiliaryPrice || oldPrice)} ${
          CURRENCY_SYMBOLS_MAP[oldAuxiliaryCurrency || RUB.value]
        }`
      }
    },
    reject() {
      this.$emit('reject')
    },
    accept() {
      this.$emit('accept')
    },
    check() {
      this.$emit('check')
    },
    fix() {
      this.$emit('fix')
    },
    close() {
      this.localVisible = false
    },
    phoneTitle(phone) {
      return formatPhone(phone)
    },
    deny() {
      this.$emit('deny')
      this.$emit('update:visible', false)
    },
    confirm() {
      this.$emit('confirm')
      this.$emit('update:visible', false)
    },
    rescheduleShow() {
      this.$emit('reschedule', this.model);
      this.$emit('update:visible', false)
    },
    denyReschedule() {
      this.$emit('denyReschedule')
      this.$emit('update:visible', false)
    },
    confirmReschedule() {
      this.$emit('confirmReschedule')
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>
.notifications-info-dialog__request-show {
  background-color: #f7f7f7;
  padding: 1em;
  padding-top: 10px;
  padding-left: 16px;
  margin-top: 8px;
  border-radius: 4px;
}

.notifications-info-dialog__request-show p {
  margin: 1em 0;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 100;
  line-height: 1.4;
  font-size: 15px;
}

.notifications-info-dialog__request-show p strong {
  display: block;
  margin-bottom: 0.3em;
  font-weight: 600;
  font-size: 16px;
  color: gray;
}

.notifications-info-dialog__request-show span {
  color: #B4B6BD;
  font-size: 11px;
}

.notifications-info-dialog__button {
  margin-top: 32px;
}

.name-and-number-link {
  font-weight: 100;
}

.show-request-added {
  font-size: 12px;
  padding-top: 4px;
}

.notifications-info-dialog__changes {
  margin-bottom: 0;
}

</style>