import { BLUE, RED, PURPLE, LIGHT_GRAY, YELLOW } from '@/constants/tagColors'

export const SHOWS_STATUS_PLANNED = { text: 'Запланирован', color: BLUE, value: 'planned' }
export const SHOWS_STATUS_COMPLETED = { text: 'Состоялся', color: PURPLE, value: 'completed' }
export const SHOWS_STATUS_POSTPONED = { text: 'Перенесен', color: LIGHT_GRAY, value: 'postponed' }
export const SHOWS_STATUS_CANCELED = { text: 'Отменен', color: RED, value: 'canceled' }
export const SHOWS_STATUS_REQUEST = { text: 'Запрос', color: YELLOW, value: 'request' }

export const SHOWS_STATUSES_GROUP = {
  [SHOWS_STATUS_PLANNED.value]: SHOWS_STATUS_PLANNED,
  [SHOWS_STATUS_COMPLETED.value]: SHOWS_STATUS_COMPLETED,
  [SHOWS_STATUS_POSTPONED.value]: SHOWS_STATUS_POSTPONED,
  [SHOWS_STATUS_CANCELED.value]: SHOWS_STATUS_CANCELED,
  [SHOWS_STATUS_REQUEST.value]: SHOWS_STATUS_REQUEST
}

export const SHOWS_STATUSES_OPTIONS = [
  { label: 'Все', value: undefined },
  { label: SHOWS_STATUS_PLANNED.text, value: SHOWS_STATUS_PLANNED.value },
  { label: SHOWS_STATUS_COMPLETED.text, value: SHOWS_STATUS_COMPLETED.value },
  { label: SHOWS_STATUS_POSTPONED.text, value: SHOWS_STATUS_POSTPONED.value },
  { label: SHOWS_STATUS_CANCELED.text, value: SHOWS_STATUS_CANCELED.value },
  { label: SHOWS_STATUS_REQUEST.text, value: SHOWS_STATUS_REQUEST.value }
]
