<template>
  <div class="notifications-changed-documents">
    <div v-for="(category, index) in categories" :key="index" class="notifications-changed-documents__category">
      <h4 class="notifications-changed-documents__title">{{ title }} : {{ category.title }}</h4>
      <div
        v-for="(item, idx) in getFilesByCategory(category.name)"
        :key="idx"
        class="notifications-changed-documents__content"
      >
        <span class="notifications-changed-documents__field-title">ДАТА</span>
        <span class="notifications-changed-documents__value">{{ formatDate(item.createdAt, '.') }}</span>
        <span class="notifications-changed-documents__line" />
        <span class="notifications-changed-documents__field-title">ФАЙЛ</span>
        <span class="notifications-changed-documents__value">{{ item.fileName }}</span>
        <div class="notifications-changed-documents__actions">
          <v-button
            class="notifications-changed-documents__action-button"
            :href="`${item.file}?token=${ACCESS_TOKEN}`"
            target="_blank"
            >Посмотреть</v-button
          >
          <v-button
            class="notifications-changed-documents__action-button"
            :href="`${item.file}?token=${ACCESS_TOKEN}`"
            download
            >Скачать</v-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { loadStorageItem } from '@/services/storage'
import { formatDate } from '@/utils/formatters'
import { DOCUMENTS_CATEGORIES } from '@/constants/documentsCategories'

const STATUS_ADDED = 'added'
const STATUS_DELETED = 'deleted'

export default {
  name: 'NotificationsChangedDocuments',
  components: { VButton },
  props: {
    action: {
      type: String,
      required: true,
      validator: val => [STATUS_ADDED, STATUS_DELETED].includes(val)
    },
    list: { type: Array, required: true }
  },
  data() {
    return {
      ACCESS_TOKEN: loadStorageItem('accessToken')
    }
  },
  computed: {
    categories() {
      return DOCUMENTS_CATEGORIES.filter(category => this.list.map(file => file.category).includes(category.name))
    },
    title() {
      const isDeletion = this.action === STATUS_DELETED
      return `${isDeletion ? 'УДАЛЕН' : 'ДОБАВЛЕН'} ФАЙЛ`
    }
  },
  methods: {
    formatDate,
    getFilesByCategory(category) {
      return this.list.filter(file => file.category === category)
    }
  }
}
</script>
