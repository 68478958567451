<template>
  <a :href="imageUrl" target="_blank" class="v-act-of-viewing-preview">
    {{ loading ? 'Загрузка...' : fileName }}
    <template v-if="!loading">
      <span v-if="!hideDeleteButton" class="v-act-of-viewing-preview__delete-button" @click.prevent="removeImage">
        <v-icon-cross />
      </span>
    </template>

    <span v-else class="v-act-of-viewing-preview__loader-wrapper">
      <span class="v-act-of-viewing-preview__loader-track">
        <span class="v-act-of-viewing-preview__loader" :style="{ width: `${progress * 100}%` }"></span>
      </span>
    </span>
  </a>
</template>

<script>
import VIconCross from '@/components/icons/VCross.vue'
import { loadStorageItem } from '@/services/storage'

export default {
  name: 'VActOfViewingPreview',
  components: { VIconCross },
  props: {
    fileName: { type: String, default: '' },
    blobImage: { type: String, default: '' },
    url: { type: String, default: '' },
    progress: { type: Number, default: undefined },
    hideDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ACCESS_TOKEN: loadStorageItem('accessToken')
    }
  },
  computed: {
    loading() {
      return this.progress !== undefined
    },
    imageUrl() {
      return this.url ? `${this.url}?token=${this.ACCESS_TOKEN}` : this.blobImage
    }
  },
  methods: {
    removeImage() {
      this.$emit('remove')
    }
  }
}
</script>
