<template>
  <div class="notifications-history-entry">
    <h4 class="notifications-history-entry__title">{{ title }}</h4>
    <div v-if="!isEditAction" class="notifications-history-entry__content">
      <span class="notifications-history-entry__field-title">ДАТА</span>
      <span class="notifications-history-entry__value">{{ historyDate }}</span>
      <span class="notifications-history-entry__line" />
      <span class="notifications-history-entry__field-title">КОММЕНТАРИЙ</span>
      <span class="notifications-history-entry__value" v-html="getTextWithLinks(text)"></span>
      <template v-if="action === $options.DELETE_OBJECT && files.length">
        <span class="notifications-history-entry__line" />
        <span class="notifications-history-entry__field-title">ФАЙЛ ({{ files.length }})</span>
        <div v-for="file in files" :key="file.id" class="notifications-history-entry__file">
          <span class="notifications-history-entry__value">{{ file.fileName }}</span>
          <div class="notifications-history-entry__actions">
            <v-button
              class="notifications-history-entry__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              target="_blank"
              >Посмотреть</v-button
            >
            <v-button
              class="notifications-history-entry__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              download
              >Скачать</v-button
            >
          </div>
        </div>
      </template>
      <template v-if="addedFiles.length">
        <span class="notifications-history-entry__line" />
        <span class="notifications-history-entry__field-title">ФАЙЛ ({{ addedFiles.length }})</span>
        <div v-for="file in addedFiles" :key="file.id" class="notifications-history-entry__file">
          <span class="notifications-history-entry__value">{{ file.fileName }}</span>
          <div class="notifications-history-entry__actions">
            <v-button
              class="notifications-history-entry__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              target="_blank"
              >Посмотреть</v-button
            >
            <v-button
              class="notifications-history-entry__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              download
              >Скачать</v-button
            >
          </div>
        </div>
      </template>
    </div>
    <template v-else>
      <notifications-changed-field
        v-for="([type, value], index) in changesList"
        :key="index"
        :object-type="objectType"
        :type="type"
        :value="value"
        class="notifications-changed-field-list__item"
      />
      <notifications-changed-files
        v-if="addedFiles.length"
        class="notifications-info-dialog__changed-fields"
        :list="addedFiles"
        action="added"
      ></notifications-changed-files>
      <notifications-changed-files
        v-if="deletedFiles.length"
        class="notifications-info-dialog__changed-fields"
        :list="deletedFiles"
        action="deleted"
      ></notifications-changed-files>
    </template>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import NotificationsChangedField from '@/components/Notifications/ChangedField.vue'
import { loadStorageItem } from '@/services/storage'
import { getTextWithLinks } from '@/utils/formatters'
import { HISTORY_TYPES, HISTORY_TYPES_MAP } from '@/constants/historyEntryTypes'
import {
  NEW_OBJECT,
  EDIT_OBJECT,
  DELETE_OBJECT,
  NOTIFICATION_ACTIONS,
  APPLY_EDIT_OBJECT,
  REJECT_EDIT_OBJECT,
  APPLY_NEW_OBJECT,
  REJECT_NEW_OBJECT
} from '@/constants/notificationActions'
import NotificationsChangedFiles from '@/components/Notifications/ChangedFiles.vue'

const ACTIONS_HISTORY_TITLE_MAP = {
  [NEW_OBJECT]: 'Добавлен',
  [DELETE_OBJECT]: 'Удалён',
  [EDIT_OBJECT]: 'Изменён',
  [APPLY_EDIT_OBJECT]: 'Изменён',
  [REJECT_EDIT_OBJECT]: 'Изменён',
  [APPLY_NEW_OBJECT]: 'Добавлен',
  [REJECT_NEW_OBJECT]: 'Добавлен'
}

export default {
  DELETE_OBJECT,
  name: 'NotificationsHistoryEntry',
  components: { NotificationsChangedFiles, NotificationsChangedField, VButton },
  props: {
    addedFiles: {
      type: Array,
      default: () => {
        return []
      }
    },
    deletedFiles: {
      type: Array,
      default: () => {
        return []
      }
    },
    text: {
      type: String,
      default: '-'
    },
    type: {
      type: String,
      required: true,
      validator: val => Object.values(HISTORY_TYPES).includes(val)
    },
    action: {
      type: String,
      required: true,
      validator: val => NOTIFICATION_ACTIONS.includes(val)
    },
    files: {
      type: Array,
      default: () => {
        return []
      }
    },
    historyDate: {
      type: String,
      required: true
    },
    changes: {
      type: Object,
      default: () => {
        return {}
      }
    },
    objectType: { type: String, required: true }
  },
  data() {
    return {
      ACCESS_TOKEN: loadStorageItem('accessToken')
    }
  },
  computed: {
    isEditAction() {
      return [EDIT_OBJECT, APPLY_EDIT_OBJECT, REJECT_EDIT_OBJECT].includes(this.action)
    },
    title() {
      return `${ACTIONS_HISTORY_TITLE_MAP[this.action]} ${
        HISTORY_TYPES_MAP.find(type => type.value === this.type).label
      }`
    },
    changesList() {
      return Object.entries(this.changes)
    }
  },
  methods: {
    getTextWithLinks
  }
}
</script>
