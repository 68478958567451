<template>
  <match-media v-slot="{ mobile }">
    <li :class="['notifications-list-item', !isRead && 'notifications-list-item--new']" v-on="$listeners">
      <div class="notifications-list-item__id">{{ id }}</div>
      <div class="notifications-list-item__content">
        <v-avatar v-if="!mobile" :src="author.photo" class="notifications-list-item__author-avatar" />
        <time v-if="mobile" class="notifications-list-item__timestamp">{{ createdAtString }}</time>
        <div class="notifications-list-item__author-name">{{ mobile ? (author.name ? author.name : 'имя не указано') : (shortName ? shortName : 'имя не указано') }}</div>
        <div class="notifications-list-item__action-wrapper">
          <div class="notifications-list-item__action">
            {{ actionDescription }}:
            <b>{{ target ? target : 'имя не указано' }}</b>
          </div>
          <time v-if="!mobile" class="notifications-list-item__timestamp">{{ createdAtString }}</time>
        </div>
      </div>
    </li>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VAvatar from '@/components/common/VAvatar.vue'
import { formatDateTime } from '@/utils/formatters'
import { shortifyName } from '@/utils/common'
import {
  NEW_OBJECT,
  EDIT_OBJECT,
  APPLY_EDIT_OBJECT,
  APPLY_NEW_OBJECT,
  APPLY_DELETE_OBJECT,
  REJECT_EDIT_OBJECT,
  REJECT_NEW_OBJECT,
  REJECT_DELETE_OBJECT,
  DELETE_OBJECT,
  SHOW_REQUEST,
  SHOW_EDIT_REQUEST,
  SHOW_CANCEL_REQUEST
} from '@/constants/notificationActions'
import { DESCRIPTIONS_MAP } from '@/constants/notificationDescriptions'

export default {
  name: 'NotificationsListItem',
  components: {
    MatchMedia,
    VAvatar
  },
  props: {
    id: { type: Number, required: true },
    author: { type: Object, required: true },
    object: { type: Object, default: () => ({}) },
    objectDraft: { type: Object, default: () => ({}) },
    action: { type: String, required: true },
    createdAt: { type: String, required: true },
    isRead: { type: Boolean, default: false },
    objectType: { type: String, required: true }
  },
  computed: {
    target() {
      if (this.action === SHOW_REQUEST) {
        return this.objectDraft?.account?.name || ''
      }
      return (
        this.objectData?.client?.name ||
        this.objectData?.account?.name ||
        this.objectData?.name ||
        this.objectData?.clientName ||
        ''
      )
    },
    shortName() {
      return shortifyName(this.author.name)
    },
    objectData() {
      const dataMap = {
        [NEW_OBJECT]: this.objectDraft || {},
        [EDIT_OBJECT]: this.object || {},
        [DELETE_OBJECT]: this.object || {},
        [APPLY_NEW_OBJECT]: this.object || this.objectDraft || {},
        [APPLY_EDIT_OBJECT]: this.object || this.objectDraft || {},
        [APPLY_DELETE_OBJECT]: this.object || this.objectDraft || {},
        [REJECT_NEW_OBJECT]: this.objectDraft || {},
        [REJECT_EDIT_OBJECT]: this.object || this.objectDraft || {},
        [REJECT_DELETE_OBJECT]: this.object || this.objectDraft || {},
        [SHOW_REQUEST]: this.objectDraft || {},
        [SHOW_EDIT_REQUEST]: this.objectDraft || {},
        [SHOW_CANCEL_REQUEST]: this.objectDraft || {}
      }
      return dataMap[this.action]
    },
    createdAtString() {
      return formatDateTime(this.createdAt, ' ', true, true)
    },
    actionDescription() {
      if (this.action === SHOW_REQUEST) {
        return `${DESCRIPTIONS_MAP[this.action][this.objectType]}: Добавлен запрос на просмотр`
      }
      return DESCRIPTIONS_MAP[this.action][this.objectType]
    }
  }
}
</script>
