<template>
  <div class="notifications-deleted-show">
    <h4 class="notifications-deleted-show__title">Удалён показ</h4>
    <div class="notifications-deleted-show__content">
      <span class="notifications-deleted-show__field-title">КЛИЕНТ</span>
      <span class="notifications-deleted-show__value">{{ clientInfo || '-' }}</span>
      <span class="notifications-deleted-show__line" />
      <span class="notifications-deleted-show__field-title">АГЕНТ</span>
      <span class="notifications-deleted-show__value">{{ showData.agent.name || '-' }}</span>
      <span class="notifications-deleted-show__line" />
      <span class="notifications-deleted-show__field-title">ОБЪЕКТ НЕДВИЖИМОСТИ</span>
      <span class="notifications-deleted-show__value">{{ formatAdvertName || '-' }}</span>
      <span class="notifications-deleted-show__line" />
      <span class="notifications-deleted-show__field-title">ДАТА ПОКАЗА</span>
      <span class="notifications-deleted-show__value">{{ showData.showDate }}</span>
      <span class="notifications-deleted-show__line" />
      <span class="notifications-deleted-show__field-title">ВРЕМЯ ПОКАЗА</span>
      <span class="notifications-deleted-show__value">{{ showData.showTime }}</span>
      <span class="notifications-deleted-show__line" />
      <span class="notifications-deleted-show__field-title">КОММЕНТАРИЙ</span>
      <span class="notifications-deleted-show__value">{{ showData.description || '-' }}</span>
      <template v-if="files.length">
        <span class="notifications-deleted-show__line" />
        <span class="notifications-deleted-show__field-title">ФОТОГРАФИЯ АКТА ПРОСМОТРА ({{ files.length }})</span>
        <div v-for="file in files" :key="file.id" class="notifications-deleted-show__file">
          <span class="notifications-deleted-show__value">{{ file.fileName }}</span>
          <div class="notifications-deleted-show__actions">
            <v-button
              class="notifications-deleted-show__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              target="_blank"
              >Посмотреть</v-button
            >
            <v-button
              class="notifications-deleted-show__action-button"
              :href="`${file.url}?token=${ACCESS_TOKEN}`"
              download
              >Скачать</v-button
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatAccountName, formatAdvertName } from '@/utils/formatters'
import VButton from '@/components/common/VButton.vue'
import { loadStorageItem } from '@/services/storage'

export default {
  name: 'NotificationsDeletedShow',
  components: { VButton },
  props: {
    showData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      ACCESS_TOKEN: loadStorageItem('accessToken')
    }
  },
  computed: {
    files() {
      return this.showData?.photos || []
    },
    formatAdvertName() {
      return this.showData?.advert ? formatAdvertName(this.showData.advert) : '-'
    },
    clientInfo() {
      return formatAccountName(this.showData)
    }
  }
}
</script>
