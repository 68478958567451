// Администратор
export const NEW_OBJECT = 'new_object'
export const EDIT_OBJECT = 'edit_object'
export const DELETE_OBJECT = 'delete_object'
// Агент
export const APPLY_EDIT_OBJECT = 'apply_edit_object'
export const REJECT_EDIT_OBJECT = 'reject_edit_object'
export const APPLY_NEW_OBJECT = 'apply_new_object'
export const REJECT_NEW_OBJECT = 'reject_new_object'
export const APPLY_DELETE_OBJECT = 'apply_delete_object'
export const REJECT_DELETE_OBJECT = 'reject_delete_object'
export const REQUEST_VIEWING = 'request_viewing'
export const SHOW_REQUEST = 'show_request'
export const SHOW_EDIT_REQUEST = 'show_edit_request'
export const SHOW_CANCEL_REQUEST = 'show_cancel_request'

export const REJECT_APPLY_EDIT = 'edit'
export const REJECT_APPLY_NEW = 'new'

export const NOTIFICATION_ACTIONS = [
  NEW_OBJECT,
  EDIT_OBJECT,
  DELETE_OBJECT,
  APPLY_EDIT_OBJECT,
  REJECT_EDIT_OBJECT,
  APPLY_NEW_OBJECT,
  REJECT_NEW_OBJECT,
  APPLY_DELETE_OBJECT,
  REJECT_DELETE_OBJECT,
  REQUEST_VIEWING,
  SHOW_REQUEST,
  SHOW_EDIT_REQUEST,
  SHOW_CANCEL_REQUEST
]
